import React from "react";
import BaseUI from "~/layouts/admin/BaseUI";
import * as request from "~/utils/httpRequest";
import { Link, useNavigate } from "react-router-dom";

function Order() {
  const navigate = useNavigate();

  const handleCreateBill = () => {
    // data.code = "HD10012";
    const customData = {
      
    };

    request
      .post("/bill", customData)
      .then((response) => {
        if (response.status === 201) {
          navigate("/admin/bill");
          
        }
      })
      .catch((error) => {
        // Xử lý lỗi nếu có
        console.error("Lỗi yêu cầu API:", error);
      });
  };

  return (
    <BaseUI>
      <div className="title">
        <h2>Tạo đơn hàng</h2>
      </div>
      <hr />
      <h6>Chi tiết đơn hàng</h6> <br />
      <input type="text" class="form-control" placeholder="Tìm kiếm sản phẩm" />
      <br />
      <button class="btn btn-outline-info" onClick={handleCreateBill}>
        Lưu
      </button>
    </BaseUI>
  );
}

export default Order;
