import React from 'react'
import BaseUI from '~/layouts/admin/BaseUI';

function OrderDetail() {
  return (
    <BaseUI>
    <div>OrderDetail</div>
    </BaseUI>
  )
}

export default OrderDetail;