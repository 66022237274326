import React from "react";
import { useState, useEffect } from "react";
import BaseUI from "~/layouts/admin/BaseUI";
import * as request from "~/utils/httpRequest";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

const BillDetail = () => {
  const [billDetail, setBillDetail] = useState([]);
  const [products, setProducts] = useState([]);
  const { id } = useParams();

  const formatDate = (dateTimeStr) => {
    const dateTime = new Date(dateTimeStr);
    const day = dateTime.getDate().toString().padStart(2, "0");
    const month = (dateTime.getMonth() + 1).toString().padStart(2, "0");
    const year = dateTime.getFullYear();
    const hours = dateTime.getHours().toString().padStart(2, "0");
    const minutes = dateTime.getMinutes().toString().padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  const DateTimeFormatter = () => {
    const originalDateTimeStr = billDetail.bill.createAt;
    const formattedDateTime = formatDate(originalDateTimeStr);
    return formattedDateTime;
  };
  useEffect(() => {
    request
      .get(`/bill/${id}`)
      .then((response) => {
        console.log(response.shoeDetail.shoe.name);
        setBillDetail(response);
        setProducts(response.shoeDetail);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  if (!billDetail.bill) {
    return (
      <BaseUI>
        <Link to="/admin/bill" class="text-secondary btn">
          &lt; Đơn hàng
        </Link>
        <h2>Có cái nịt...</h2>
      </BaseUI>
    );
  }

  return (
    <BaseUI>
      <Link to="/admin/bill" class="text-secondary btn">
        &lt; Đơn hàng
      </Link>
      <h2>#{billDetail.bill.code}</h2>
      <p class="text-secondary">
        <DateTimeFormatter></DateTimeFormatter>
      </p>
      <hr />
      <h6>Chi tiết đơn hàng</h6>
      {products.shoe.name}
    </BaseUI>
  );
};

export default BillDetail;
