import BaseUI from './layouts/admin/BaseUI';
import AddStaff from './pages/admin/account/staff/AddStaff';
import Staff from './pages/admin/account/staff/Staff';
import StaffDetail from './pages/admin/account/staff/StaffDetail';
import Bill from './pages/admin/bill/Bill';
import BillDetail from './pages/admin/bill/BillDetail';
// import CreateBill from './pages/admin/bill/CreateBill';
import Order from './pages/admin/order/Order';
import OrderDetail from './pages/admin/order/OrderDetail';
import AddProduct from './pages/admin/product/AddProduct';
import Product from './pages/admin/product/Product';
import ProductDetail from './pages/admin/product/ProductDetail';
import ProductAttribute from './pages/admin/product/attribute/ProductAttribute';
import Voucher from './pages/admin/voucher/Voucher';
const publicRouters = [
    { path: "/", element: BaseUI },
    // { path: "/admin/dashboard", element: Dashboard },
    // { path: "/admin/profile", element: Profile },
    // { path: "/admin/thong-ke", element: Dashboard },
    { path: "/admin/order", element: Order },
    { path: "/admin/order/:id", element: OrderDetail },
    { path: "/admin/bill", element: Bill },
    { path: "/admin/bill/:id", element: BillDetail },
    // { path: "/admin/bill/create", element: CreateBill },
    { path: "/admin/product", element: Product },
    { path: "/admin/product/add", element: AddProduct },
    { path: "/admin/product/attribute", element: ProductAttribute },
    { path: "/admin/product/:id", element: ProductDetail },
    { path: "/admin/voucher", element: Voucher },
    { path: "/admin/voucher/:id", element: Voucher },
    { path: "/admin/staff", element: Staff },
    { path: "/admin/staff/add", element: AddStaff },
    { path: "/admin/staff/:id", element: StaffDetail },
    // { path: '*', element: NotFound}
];

const privateRouters = [];

export { publicRouters, privateRouters };