import React from 'react'
import BaseUI from '~/layouts/admin/BaseUI';

function ProductAttribute() {
  return (
    <BaseUI>
    <div>AttributeProduct</div>
    </BaseUI>
  )
}

export default ProductAttribute;