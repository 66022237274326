import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import Loading from "~/components/Loading/Loading";
import Pagination from "~/components/Pagination";
import BaseUI from "~/layouts/admin/BaseUI";
import FormatDate from "~/utils/FormatDate";
import * as request from "~/utils/httpRequest";

function Staff() {
  const [staffList, setStaffList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [searchValue, setSearchValue] = useState("");
  const [staffStatus, setStaffStatus] = useState("");
  const [pageSize, setPageSize] = useState(3);

  useEffect(() => {
    loadData();
  }, [searchValue, pageSize, staffStatus, currentPage]);

  const loadData = async () => {
    try {
      const response = await request.get("/staff", {
        params: {
          value: searchValue,
          page: currentPage,
          pageSize: pageSize,
          status: staffStatus,
        },
      });
      setStaffList(response.content);
      setTotalPages(response.totalPages);
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangePageSize = (e) => {
    setPageSize(e.target.value);
  };
  const handleChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };
  const handleChangeStatus = (e) => {
    setStaffStatus(e.target.value);
    console.log(e.target.value);
  };
  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1) pageNumber = 1;
    setCurrentPage(pageNumber);
  };

  const handleUpdateStatus = (staff) => {
    staff.deleted === true ? staff.deleted = false : staff.deleted = true
    // console.log(staff)
    // const staffUpdate  = {...staff};
    // staffUpdate.deleted = true;
    swal(`Cập nhật trạng thái cho ${staff.name} thành ${staff.deleted === true ? "Đã nghỉ" : "Đang làm"} ?`, {
      icon: "warning",
      buttons: ["Không", "Đồng ý!"],
    }).then((action) => {
      if (action) {
        request
          .put(`/staff/${staff.id}`, staff)
          .then((response) => {
            if (response.status === 200) {
              toast.success("Cập nhật thành công!");
              loadData();
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    });
  };

  return (
    <BaseUI>
      <div className="">
        <h6>Danh sách nhân viên</h6>
        <div className="d-flex">
          <div className="p-2 flex-grow-1">
            <input
              className="form-control form-control-sm me-2"
              name="ten"
              type="search"
              defaultValue={""}
              placeholder="Tìm kiếm nhân viên theo tên, email ..."
              onChange={(event) => handleChangeSearchValue(event)}
            />
          </div>
          <div className="p-2">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="status"
                id="statusAll"
                value=""
                onChange={(event) => handleChangeStatus(event)}
                defaultChecked={true}
              />
              <label class="form-check-label" for="statusAll">
                Tất cả
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="status"
                id="statusOnline"
                value="false"
                onChange={(event) => handleChangeStatus(event)}
              />
              <label class="form-check-label" for="statusOnline">
                Đang làm
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="status"
                id="statusOffline"
                value="true"
                onChange={(event) => handleChangeStatus(event)}
              />
              <label class="form-check-label" for="statusOffline">
                Đã nghỉ
              </label>
            </div>
          </div>
          <div className="p-2">
            <select
              className="form-select form-select-sm"
              onChange={(event) => handleChangePageSize(event)}
            >
              <option value={3}>3</option>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
            </select>
          </div>
          <div className="p-2">
            <Link
              type="button"
              className="btn btn-warning btn-sm"
              to={"/admin/staff/add"}
            >
              <i className="fas fa-plus-circle"></i> Thêm nhân viên
            </Link>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-hover table-bordered">
            <thead className="small bg-warning">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Tên</th>
                <th scope="col">Email</th>
                <th scope="col">Ngày tạo</th>
                <th scope="col">Avatar</th>
                <th scope="col">Trạng thái</th>
                <th scope="col">Thao tác</th>
              </tr>
            </thead>
            <tbody>
              {totalPages === 0 ? (
                <tr className="text-center fw-semibold">
                  <td colSpan={8}>Oops!, không có bản ghi nào...</td>
                </tr>
              ) : (
                staffList.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>
                      <FormatDate date={item.createAt} />
                    </td>
                    <td>
                      <img
                        src={`https://res.cloudinary.com/beeshoes/image/upload/v1690189926/${item.avatar}`}
                        alt="p"
                        width={"48px"}
                        height={"48px"}
                        className="rounded-circle object-fit-cover"
                      />
                    </td>
                    <td>
                      <button
                        onClick={() => handleUpdateStatus(item)}
                        class={`badge border-0 w-100 ${
                          item.deleted === true ? "bg-danger" : "bg-primary"
                        }`}
                      >
                        {item.deleted === true ? "Đã nghỉ" : "Đang làm"}
                      </button>
                    </td>
                    <td>
                      <Link
                        to={`/admin/staff/${item.id}`}
                        className="btn btn-sm text-warning"
                      >
                        <i className="fas fa-edit"></i>
                      </Link>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          handleChange={handlePageChange}
        />
      </div>
    </BaseUI>
  );
}

export default Staff;
