import React, { useState } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import FormatCurrency from "~/utils/FormatCurrency";

function TableProduct({ props, handleDelete, handleUpdate }) {
  const [selectedImages, setSelectedImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);

  const handleImageSelect = (event, index) => {
    const files = Array.from(event.target.files);

    setSelectedImages((prevSelectedImages) => {
      const updatedSelectedImages = [...prevSelectedImages];
      updatedSelectedImages[index] = files;
      return updatedSelectedImages;
    });

    handleUpdate(index, { ...props[index], images: files})

    const previews = files.map((file) => URL.createObjectURL(file));

    setPreviewImages((prevPreviewImages) => {
      const updatedPreviewImages = [...prevPreviewImages];
      updatedPreviewImages[index] = previews;
      return updatedPreviewImages;
    });
  };

  const handleChangeQuantity = (event, index) => {
    const value = parseInt(event.target.value);
    value < 1 ? toast.error("Số lượng phải lớn hơn 1!") : handleUpdate(index, { ...props[index], quantity: value })
  };
  const handleChangePrice = (event, index) => {
    const value = parseInt(event.target.value);
    value < 1 ? toast.error("Đơn giá không hợp lệ!") : handleUpdate(index, { ...props[index], price: value });
  };
  const deleteProductDetail = (index) => {
    swal({
      title: "Xác nhận xóa?",
      text: "Không thể khôi phục hành động này!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDelete(index);
        const updatedPreviewImages = [...previewImages];
        updatedPreviewImages.splice(index, 1);
        setPreviewImages(updatedPreviewImages);
        toast.success("Xóa thành công!");
      }
    });
  };

  return (
    <div className="card mt-2 border-0">
      <div className="card-header border-0 bg-secondary-subtle">
        <span className="fw-semibold">Danh sách các sản phẩm cùng loại</span>
      </div>

      <div className="table-responsive">
        <table className="table table-sm text-nowrap">
          <thead className="bg-warning">
            <tr>
              <th>#</th>
              <th>Sản phẩm</th>
              <th>Số lượng</th>
              <th>Đơn giá</th>
              <th>Danh mục</th>
              <th>Thương hiệu</th>
              <th>Loại đế</th>
              <th>Ảnh</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {props.map((option, index) => (
              <tr key={index}>
                {option.shoe === undefined || option.shoe === null ? null : (
                  <>
                    <td>{index + 1}</td>
                    <td>
                      {option.shoe === undefined || option.shoe === null
                        ? "Vui lòng chọn sản phẩm"
                        : option.shoe.name}{" "}
                      [{option.color.name} - {option.size.name}]
                    </td>
                    <td width="100px">
                      <input
                        type="text"
                        className="form-control form-control-sm input-small"
                        name=""
                        defaultValue={option.quantity}
                        onChange={(event) => handleChangeQuantity(event, index)}
                      />
                    </td>
                    <td width="100px">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name=""
                        defaultValue={option.price}
                        onChange={(event) => handleChangePrice(event, index)}
                      />
                    </td>
                    <td>
                      {option.shoe.category.name}
                    </td>
                    <td>{option.shoe.brand.name}</td>
                    <td>{option.sole == null ? "" : option.sole.name}</td>
                    <td>
                      <button
                        className="btn btn-sm"
                        data-bs-toggle="modal"
                        data-bs-target={`#imageModal-${index}`}
                      >
                        <i className="fas fa-image"></i>
                      </button>
                      <div
                        className="modal fade"
                        id={`imageModal-${index}`}
                        tabIndex="-1"
                        aria-labelledby={`imageModalLabel-${index}`}
                        aria-hidden="true"
                      >
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h1
                                className="modal-title fs-5"
                                id={`imageModalLabel-${index}`}
                              >
                                Thêm ảnh
                              </h1>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <input
                                type="file"
                                className="form-control"
                                accept="image/*"
                                multiple={true}
                                onChange={(event) =>
                                  handleImageSelect(event, index)
                                }
                              />
                              <div className="row mt-3">
                                {previewImages[index]?.map((previewUrl, i) => (
                                  <div className="col-xl-4">
                                    <img
                                      key={i}
                                      src={previewUrl}
                                      alt="Preview"
                                      style={{ width: "100%" }}
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <button
                        className="btn btn-sm"
                        onClick={() => deleteProductDetail(index)}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TableProduct;
