import React, { useEffect, useState } from "react";
import * as request from "~/utils/httpRequest";

const GHNInfo = ({ dataAddress, prov, distr, war }) => {
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);

  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedWard, setSelectedWard] = useState(null);

  const configApi = {
    headers: {
      Token: "aef361b5-f26a-11ed-bc91-ba0234fcde32",
      "Content-Type": "application/json",
      ShopId: 124173,
    },
  };

  useEffect(() => {
    request
      .get(
        "https://dev-online-gateway.ghn.vn/shiip/public-api/master-data/province",
        configApi
      )
      .then((response) => {
        setProvinces(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
    if (distr !== undefined && war !== undefined) {
      request
        .get(
          `https://dev-online-gateway.ghn.vn/shiip/public-api/master-data/district?province_id=${prov}`,
          configApi
        )
        .then((response) => {
          setDistricts(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
      request
        .get(
          `https://dev-online-gateway.ghn.vn/shiip/public-api/master-data/ward?district_id=${distr}`,
          configApi
        )
        .then((response) => {
          setWards(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);

  const handleProvinceChange = (provinceCode) => {
    request
      .get(
        `https://dev-online-gateway.ghn.vn/shiip/public-api/master-data/district?province_id=${provinceCode}`,
        configApi
      )
      .then((response) => {
        setDistricts(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
    setSelectedProvince(provinceCode);
  };

  const handleDistrictChange = (districtCode) => {
    request
      .get(
        `https://dev-online-gateway.ghn.vn/shiip/public-api/master-data/ward?district_id=${districtCode}`,
        configApi
      )
      .then((response) => {
        setWards(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
    setSelectedDistrict(districtCode);
  };

  useEffect(() => {
    setSelectedProvince(prov);
    setSelectedDistrict(distr);
    setSelectedWard(war);
  }, [prov, distr, war]);

  const handleWardChange = (wardCode) => {
    dataAddress({
      province: selectedProvince,
      district: selectedDistrict,
      ward: wardCode,
    });
  };

  return (
    <>
      <div className="col-xl-4">
        <label class="form-label">Tỉnh / Thành phố</label>
        <select
          className="form-select form-select"
          onChange={(e) => handleProvinceChange(e.target.value)}
        >
          <option value="">Chọn tỉnh</option>
          {provinces.map((province) => (
            <option
              key={province.ProvinceID}
              value={province.ProvinceID}
              selected={province.ProvinceID == prov}
            >
              {province.ProvinceName}
            </option>
          ))}
        </select>
      </div>
      <div className="col-xl-4">
        <label class="form-label">Quận / Huyện</label>
        <select
          className="form-select form-select"
          onChange={(e) => handleDistrictChange(e.target.value)}
        >
          <option value="">Chọn huyện</option>
          {districts.map((district) => (
            <option
              key={district.DistrictID}
              value={district.DistrictID}
              selected={district.DistrictID == distr}
            >
              {district.DistrictName}
            </option>
          ))}
        </select>
      </div>
      <div className="col-xl-4">
        <label class="form-label">Xã / Phường / Thị trấn</label>
        <select
          className="form-select form-select"
          onChange={(e) => handleWardChange(e.target.value)}
        >
          <option value="">Chọn xã</option>
          {wards.map((ward) => (
            <option
              key={ward.WardCode}
              value={ward.WardCode}
              selected={ward.WardCode == selectedWard}
            >
              {ward.WardName}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default GHNInfo;
