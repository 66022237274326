import React from "react";
import BaseUI from "~/layouts/admin/BaseUI";
import { useState, useEffect } from "react";
import * as request from "~/utils/httpRequest";
import Pagination from "~/components/Pagination";
import { Link } from "react-router-dom";

const Bill = () => {
  const [billList, setBillList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [pageSize, setPageSize] = useState(3);

  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1) pageNumber = 1;
    setCurrentPage(pageNumber);
  };

  const handleChangePageSize = (e) => {
    setPageSize(e.target.value);
  };
  const handleChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    request
      .get("/bill", {
        params: {
          name: searchValue,
          page: currentPage,
          pageSize: pageSize,
        },
      })
      .then((response) => {
        setBillList(response.content);
        setTotalPages(response.totalPages);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage, pageSize, searchValue]);

  return (
    <BaseUI>
      <div class="">
        <div className="title">
          <h2>Đơn hàng</h2>
        </div>
        <div className="d-flex">
          <div className="p-2 flex-grow-1">
            <input
              className="form-control form-control-sm me-2"
              name="code"
              type="search"
              defaultValue={""}
              placeholder="Mã hóa đơn..."
              onChange={(event) => handleChangeSearchValue(event)}
            />
          </div>
          <div className="p-2">
            {/* <div class="input-group input-group-sm">
              <input
                type="text"
                class="form-control"
                placeholder="Nhập giá tối thiểu..."
                onChange={(event) => setMinPriceValue(event.target.value)}
              />
              <span class="input-group-text bg-warning-subtitle">đến</span>
              <input
                type="text"
                class="form-control"
                placeholder="Nhập giá tối đa..."
                onChange={(event) => setMaxPriceValue(event.target.value)}
              />
            </div> */}
          </div>
          <div className="p-2">
            <select
              className="form-select form-select-sm"
              onChange={(event) => handleChangePageSize(event)}
            >
              <option value={3}>3</option>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
            </select>
          </div>
          <div className="p-2">
            <Link
              type="button"
              className="btn btn-warning btn-sm"
              to={"/admin/order"}
            >
              <i className="fas fa-plus-circle"></i> Tạo đơn hàng
            </Link>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-hover table-bordered">
            <thead className="small bg-warning">
              <tr>
                <th>#</th>
                {/* <th>STT</th> */}
                <th>Mã HĐ</th>
                <th>Ngày Tạo</th>
                <th>Khách hàng</th>
                <th>Số điện thoại</th>
                <th>Thanh toán</th>
                <th>Tổng tiền</th>
              </tr>
            </thead>
            <tbody>
              {billList.map((item, index) => (
                <tr key={item.id}>
                  <td>
                    <input type="checkbox" name="" id="" />
                  </td>
                  {/* <td>{index + 1}</td> */}
                  <td>
                    <Link 
                      to={`/admin/bill/${item.id}`}
                      className="btn text-info"
                    >
                      #{item.code}
                    </Link>
                  </td>
                  <td>{item.createAt}</td>
                  <td>tên khách</td>
                  <td></td>
                  <td>Trạng thái</td>
                  <td>{item.totalMoney}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        handleChange={handlePageChange}
      />
    </BaseUI>
  );
};

export default Bill;
