import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SidebarMenu from "./components/SidebarMenu";
import sidebarData from './components/sidebarData';
import SidebarSubMenu from "./components/SidebarSubMenu";

function BaseUI({ children }) {
  const [toggleSidebar, setToggleSidebar] = useState("");
  const [toggleSidebarIcon, setToggleSidebarIcon] = useState("fa-xmark");
  const [activeMenu, setActiveMenu] = useState(null);
  const handleClickToggle = () => {
    setToggleSidebar(toggleSidebar === "d-none" ? "d-block" : "d-none");
    setToggleSidebarIcon(toggleSidebarIcon === "fa-xmark" ? "fa-bars" : "fa-xmark");
  };

  const handleMenuClick = async (menu) => {
    
    await setActiveMenu(menu);
  }

  return (
    <>
      <section className="d-flex h-100" style={{height: "100%"}}>
        <aside id="sidebar" className={`sidebar ${toggleSidebar}`}>
          <div
            className="p-3 bg-light"
            style={{ width: "250px", height: "100%"}}
          >
            <Link className="d-flex align-items-center pb-3 mb-3 link-body-emphasis text-decoration-none border-bottom">
              <img src="/logo.png" alt="" width="100%" />
            </Link>
            <ul className="list-unstyled ps-0">
              {sidebarData.map((item, k) => {
                return (
                  <SidebarMenu title={item.name} path={item.path} collapseId={`menu${k}`} subMenu={item.submenu} />
                )
              })}
            </ul>
          </div>
        </aside>
        <div className="w-100 mt-2 p-1">
          <nav className="navbar navbar-expand-sm shadow rounded-2">
            <div className="container-fluid">
              <span className="navbar-brand">
                <span
                  id="toggleSidebar"
                  onClick={handleClickToggle}
                >
                  <i className={`fa-solid ${toggleSidebarIcon}`} />
                </span>
              </span>
              <div className="collapse navbar-collapse" id="collapsibleNavId">
                <ul className="navbar-nav me-auto mt-2 mt-lg-0">
                  <li className="nav-item">
                    <Link className="nav-link fw-semibold">
                      Bee Shoes Admin Dashboard
                    </Link>
                  </li>
                </ul>
                <div className="d-flex small justify-content-center align-items-center">
                  <div className="rounded-circle">
                  <i className="fas fa-bell rounded-circle p-1 me-2"></i>
                  </div>
                  <div className="">
                    <i className="fas fa-user me-2"></i> Xin chào, <strong>Vũ Nguyên Hướng</strong>
                  </div>
                </div>
              </div>
            </div>
          </nav>
          <article style={{minHeight: "100vh"}} className="mb-3">
            <div className="container-fluid p-4">
              <div className="container bg-white rounded-3 shadow-lg p-4 mb-5 bg-secondary">
              {children}
              </div>
            </div>
          </article>
          <Footer />
        </div>
      </section>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default BaseUI;
