const sidebarData = [
    {
        path: "/admin/product",
        icon: "",
        name: 'Tổng quan'
    },
    {
        icon: "",
        name: 'Quản lý giao dịch',
        submenu: [
            {
                path: '/admin/order',
                icon: "fa-truck-fast",
                name: "Bán hàng"
            },
            {
                path: '/admin/bill',
                icon: "fa-wallet",
                name: "Quản lý hóa đơn"
            },
        ]
    },
    {
        icon: "",
        name: 'Quản lý sản phẩm',
        submenu: [
            {
                path: '/admin/product',
                icon: "fa-seedling",
                name: "Sản phẩm"
            },
            {
                path: '/admin/product/attribute',
                icon: "fa-brands fa-battle-net",
                name: "Thuộc tính sản phẩm"
            },
        ]
    },
    {
        icon: "",
        name: 'Quản lý tài khoản',
        submenu: [
            {
                path: '/admin/staff',
                icon: "fa-user-tie",
                name: "Quản lý nhân viên"
            },
            {
                path: '/admin/product/attribute',
                icon: "fa-user-secret",
                name: "Quản lý khách hàng"
            },
        ]
    },
    {
        icon: "",
        name: 'Quản lý Voucher',
    },
    {
        icon: "",
        name: 'Tài khoản',
        submenu: [
            {
                path: '/admin/product',
                icon: "fa-user",
                name: "Thông tin cá nhân"
            },
            {
                path: '/admin/bill',
                icon: "fa-gear",
                name: "Cài đặt"
            },
            {
                path: '/admin/bill',
                icon: "fa-right-from-bracket",
                name: "Đăng xuất"
            },
        ]
    },
];

export default sidebarData;