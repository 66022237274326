import React from 'react'
import BaseUI from '~/layouts/admin/BaseUI'

function Voucher() {
  return (
    <BaseUI>
    <div>Voucher</div>
    </BaseUI>
  )
}

export default Voucher;